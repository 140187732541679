import { Component, OnInit } from '@angular/core';

import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { FuncionesUtil } from '@app/common/util/funciones-util';
import { TipoConcepto } from '@app/enums/tipo-concepto';
import { Cliente } from '@app/model/cliente.model';
import { ConceptoProyecto } from '@app/model/concepto-proyecto.model';
import { Proyecto } from '@app/model/proyecto.model';
import { AlertService } from '@app/service/alert.service';
import { ConceptoProyectoService } from '@app/service/concepto-proyecto.service';
import { DataService } from '@app/service/data.service';
import { EstadisticaService } from '@app/service/estadistica.service';
import { retry } from 'rxjs/operators';

@Component({
  selector: 'app-estadistica',
  templateUrl: './estadistica.component.html',
  styleUrls: ['./estadistica.component.scss']
})
export class EstadisticaComponent implements OnInit {

  cliente!: Cliente;
  proyecto!: Proyecto;
  filterForm!: FormGroup;
  campoRequerido: string = "Campo Requerido";
  estadisticasNR!: ConceptoProyecto[];
  estadisticasHH!: ConceptoProyecto[];

    
  constructor(
    private dataService: DataService,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private conceptoProyectoService: ConceptoProyectoService,
    private EstadisticaService: EstadisticaService
  ) {    
  }

  ngOnInit(): void {
    this.crearFormulario(this.formBuilder);
    
    this.cliente = JSON.parse(sessionStorage.getItem('clienteSeleccionado')!);
    this.proyecto = JSON.parse(sessionStorage.getItem('proyectoSeleccionado')!);
    this.dataService.updateNombreCliente(this.cliente.nombre);
    this.dataService.updateNombreProyecto(this.proyecto.nombre);

    this.cargarInformes();
  }

  private crearFormulario(fb: FormBuilder) {
    this.filterForm = fb.group({
      fechaDesde: new FormControl('', [Validators.required]),
      fechaHasta: new FormControl('', [Validators.required]),
      informe: new FormControl({ value: '', disabled: false })
    });
  }

  async cargarInformes() {
    this.conceptoProyectoService.buscarPorGrupo(TipoConcepto.INFORMES.toString(), this.proyecto.id)
      .pipe(retry(1))
      .subscribe(
        respuesta => {
          if (!respuesta.error) {
            this.estadisticasNR = respuesta.data;
          } else {
            this.alertService.error(respuesta.mensaje, 'Error');
          }
        },
        error => {
          this.alertService.error(error, 'Error');
        }
      );
  }

  generarReporte(): void {
    this.EstadisticaService.obtenerInforme(
      this.proyecto.id, this.filterForm.controls.fechaDesde.value, this.filterForm.controls.fechaHasta.value,
      this.filterForm.controls.informe.value)
      .pipe(retry(1))
      .subscribe(
        blob => {
          var filename = "informe.pdf";
          var mime = "application/pdf";
          FuncionesUtil.downloadFile(blob, filename, mime);
        },
        error => {
          this.alertService.error(error, 'Error');
        }
      );
  }
}
