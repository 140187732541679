import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormatosFechas } from '@app/enums/formatos-fechas';
import { LocaleAbreviacion } from '@app/enums/locale-abreviacion';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EstadisticaService {

  private informeV1Url = '/v1/informe/';
  private datePipe = new DatePipe(LocaleAbreviacion.esCL); 

  constructor(
    private httpClient: HttpClient
  ) { }

  obtenerInforme(idProyecto: number, fDesde: Date, fHasta: Date, informe: string): Observable<Blob> {
    const url = this.informeV1Url;

    let params = new HttpParams()
      .set('idProyecto', idProyecto.toString())
      // formatear fechas en formato yyyy-MM-dd
      .set('fechaDesde', this.datePipe.transform(fDesde, FormatosFechas.YYYYMMDD)!.toString())
      .set('fechaHasta', this.datePipe.transform(fHasta, FormatosFechas.YYYYMMDD)!.toString())
      .set('informe', informe);

    let headers = new HttpHeaders({
      'CUSTOM': 'anything',
      'Content-Type': 'application/pdf',
      'Accept': 'application/pdf'
    });

    return this.httpClient.get(url, { headers, params, responseType: 'blob' });
  }
}
